import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "../css/default.css";

function NotFound() {
    return (
        <div className="notFound-wrapper">
            <div>
                <h1>404</h1>
                <p>
                    <AniLink fade to="/">
                        Til baka
                    </AniLink>
                </p>
            </div>
        </div>
    );
}

export default NotFound;
